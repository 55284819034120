export function downloadBlob({
  data,
  type,
  filename,
}: {
  data: BufferSource | Blob | string;
  type: string;
  filename: string;
}) {
  const url = window.URL.createObjectURL(new Blob([data], { type }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}
