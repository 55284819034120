import { Money } from 'ts-money';

import { Money as GQLMoney } from '@/generated/graphql';

export function formatMoney(money: GQLMoney): string;
export function formatMoney(money: number | bigint, currency: string): string;
export function formatMoney(
  amount: number | bigint | GQLMoney,
  currency?: string,
) {
  if (typeof amount !== 'number' && typeof amount !== 'bigint') {
    return formatMoney(BigInt(amount.amount), amount.currencyCode);
  }
  const [symbol, value] = formatMoneyIntoParts(amount, currency || '');

  return `${symbol}${value}`;
}

export function formatMoneyIntoParts(
  amount: number | bigint,
  currency: string,
) {
  // ts-money doesn't support bigint right now
  // but it's apparently on the roadmap
  const money = new Money(Number(amount), currency);

  return [money.getCurrencyInfo().symbol, money.toString()] as const;
}
